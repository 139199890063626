.img {
  margin-right: 10px;
}

.btn {
  padding: 10px 18px;
  flex-direction: row;
  width: 100%;
  height: 40px;
}

.link {
  display: flex;
  align-items: center;
}