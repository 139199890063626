.content {
  margin: 45px 0;
  width: 100%;
}

.line {
  background-color: var(--black10);
  height: 1px;
  width: 100%;
}

.text {
  padding: 0 12px;
  font-size: 14px;
  color: var(--grey2);
}