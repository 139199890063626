@import 'styles/layers.scss';

@layer component {
  .dialog {
    min-width: 377px;
  }
  
  .body {
    padding: 17px 22px 22px;
  }
  
  .title {
    font-size: 20px;
  }
  
  .title-wrap {
    margin-bottom: 25px;
  }
  
  .subTitle {
    font-size: 14px;
    margin-bottom: 16px;
  }
  
  .line {
    text-align: center;
    margin-bottom: 30px;
    font-size: 12px;
  }
  
  .btns {
    row-gap: 8px;
    width: 100%;
  }

  .notification {
    margin-top: 12px;
  }
}