@import 'styles/layers.scss';

@layer uikit {
  $z-index: 1000; // for hide previous modal

  .dialog {
    z-index: $z-index;
  }

  .backdrop {
    z-index: $z-index;
  }

  .modal {
    transition: none;
  }

  .content {
    background-color: var(--black15);
    border-radius: 4px;
    z-index: $z-index;
  }

  .icon-close {
    position: absolute;
    top: 11px;
    right: 11px;
    z-index: 10;
    color: var(--grey2);
    cursor: pointer;
  }

  .icon-close:hover {
    color: var(--white);
  }
}